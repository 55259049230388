//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import EntityCharts from "@/components/EntityCharts";
import CalendarMixin from "@/mixins/Calendar";
import RepricerMixin from "@/mixins/Repricer";
import AppSelect from "@/components/AppSelect.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import WarningRepricer from "@/components/Alert/WarningRepricer.vue";
import { getEntityCellsConfig } from "@/utils/enums";
import HasTokens from "@/components/HasTokensRepricer.vue";
import ScriptsTable from "@/components/Repricer/ScriptsTable.vue";
import MyProductsTable from "@/components/Repricer/MyProductsTable.vue";
import ProductsManagementTable from "@/components/Repricer/ProductsManagementTable.vue";
import EventsLogTable from "@/components/Repricer/EventsLogTable.vue";
// import AppTabs from "@/components/AppTabs.vue";

// const tableComponents = {
//     categories: CategoriesTable,
// };
export default {
  mixins: [CalendarMixin, RepricerMixin],
  meta: {
    title: "Репрайсер - Обзор показателей"
  },
  data() {
    const localStorageCalendar = JSON.parse(localStorage.getItem("repricerCalendar"));

    // const tabs_tables = [
    //     {
    //         id: "reviews-new",
    //         title: "Отзывы без ответа",
    //     },
    //     {
    //         id: "reviews-archive",
    //         title: "Обработанные отзывы",
    //     },
    //     {
    //         id: "categories",
    //         title: "Статистика категорий",
    //     },
    // ];

    if (localStorageCalendar) {
      this.$store.dispatch("repricer/updateCalendar", localStorageCalendar);
    }
    return {
      calendarInternal: null,
      calendar: this.$store.state.repricer.calendar,
      // tabs_tables,
      // tabs_tables_model: tabs_tables[0],
      totalScripts: 0,
      limit: null
    };
  },
  created() {
    this.loadRepriceLimits();
  },
  methods: {
    async onAddRepriceLimit() {
      if (this.user.parent) {
        return;
      }
      await this.$modal.process(() => import("@/components/Billing/ModalPayment"), {
        type: "addLimit",
        preferredPlanId: 30,
        subscriptionAddLimit: this.currentSubscriptions[0],
        user: this.user
      });
      // await this.load();
    },

    async loadOverview() {
      var _total;
      // let dynamic = null;
      let total = null;
      // if (this.marketplace.title === "Wildberries") {
      // dynamic = await this.$store.dispatch(
      //     "repricer/getWbHighlightsByDays",
      //     this.reportSettings
      // );
      // }
      total = await this.$store.dispatch("repricer/getHighlights", this.reportSettings);
      // if (this.marketplace.title === "OZON") {
      //     // dynamic = await this.$store.dispatch(
      //     //     "repricer/getOzHighlightsByDays",
      //     //     this.reportSettings
      //     // );
      //     total = await this.$store.dispatch(
      //         "repricer/getOzHighlights",
      //         this.reportSettings
      //     );
      // }
      let res = {};
      (_total = total) === null || _total === void 0 || _total.forEach(item => {
        res = {
          ...res,
          [item.id]: {
            total: Number(item.value).toFixed(2),
            dynamic: []
          }
        };
      });
      // const keys = Object.keys(dynamic?.data[0]);
      // total?.data.forEach((item, index) => {
      //     res = {
      //         ...res,
      //         [keys[index + 2]]: {
      //             total: Number(item.value).toFixed(0),
      //             dynamic: dynamic.data.map((i) => {
      //                 return i[`${item.id}`];
      //             }),
      //         },
      //     };
      // });
      return res;
    },
    async loadRepriceLimits() {
      const {
        data
      } = await this.$store.dispatch("repricer/getRepricerLimit");
      this.limit = {
        count: data.sku_count,
        limit: data.sku_quota
      };
    },
    // getTableComponent(id) {
    //     return tableComponents[id];
    // },
    addScript() {
      // const limit = this.$access.getLimit("repricer");
      // if (this.totalScripts >= limit) {
      //     this.$modal.open(
      //         () =>
      //             import(
      //                 "@/components/modals/access/ModalLimitReachedTemplate"
      //             ),
      //         {
      //             alert: "Превышен лимит на вашем тарифном плане",
      //             title: "Исчерпан лимит сценариев. Для увеличения лимита необходимо перейти на старший тариф",
      //         }
      //     );
      // } else {
      this.$router.push({
        name: "RepricerScript"
      });
      // }
    },

    updateTotal(val) {
      this.totalScripts = val;
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    currentSubscriptions() {
      return this.$store.state.billing.currentSubscriptions;
    },
    cells() {
      return this.$store.state.repricer.marketplace.title === "Wildberries" ? getEntityCellsConfig("repricerWb") : getEntityCellsConfig("repricerOzon");
    },
    // tableKey() {
    //     return `${this.tabs_tables_model.id}`;
    // },
    reportSettings() {
      const tokens = this.selectedTokens.map(item => item.id);
      if (!this.calendar) {
        return null;
      }
      // if (!tokens.length) {
      //     return null;
      // }
      const dates = [this.calendar[0], this.calendar[1]];
      return {
        mp: this.marketplace.id,
        date: dates[0],
        date2: dates[1],
        tokens,
        page_size: 10
      };
    },
    reportSettingsNoDates() {
      const result = {
        ...this.reportSettings
      };
      delete result.date;
      delete result.date2;
      return result;
    }
  },
  watch: {
    calendar() {
      this.$store.dispatch("repricer/updateCalendar", this.calendar);
      localStorage.setItem("repricerCalendar", JSON.stringify(this.calendar));
    }
  },
  components: {
    MainTitle,
    EntityCharts,
    ReportPage,
    AppSelect,
    AppSelectMulti,
    WarningRepricer,
    HasTokens,
    DataLoading,
    ScriptsTable,
    MyProductsTable,
    ProductsManagementTable,
    EventsLogTable
    // AppTabs,
  }
};